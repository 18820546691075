import Link from "next/link";
import { useTranslation } from "next-i18next";
import { getIllustrationIconName } from "common/utils";
import Button from "components/Button";
import Icon from "components/Icon/Icon";
import Modal, { ModalProps } from "components/Modal";
import TagList from "components/TagList";
import { ServicePartsFragment } from "types/graphql";
import styles from "./ServiceModal.module.css";

type ServiceModalProps = ModalProps & {
  service?: ServicePartsFragment | null;
};

const ServiceModal = ({ service, ...props }: ServiceModalProps) => {
  const { t } = useTranslation("common");

  const { name, content, tags, icon } = service?.attributes ?? {};
  const iconName = getIllustrationIconName(icon);

  return (
    <Modal {...props} open={Boolean(service)}>
      <div className={styles["container"]}>
        <Icon name={iconName} className={styles["service-icon"]} />
        <p className={styles["title"]}>{name}</p>
        <TagList tags={tags?.data} limit={5} className={styles["tag-list"]} />
        <article
          className={styles["content"]}
          dangerouslySetInnerHTML={{ __html: content ?? "" }}
        />
        <Link className={styles["project-button"]} href="/projects">
          <Button variant="inverted">{t("see-projects")}</Button>
        </Link>
      </div>
    </Modal>
  );
};

export default ServiceModal;
