import classNames from "classnames";
import Img from "components/Img";
import { ClassNameArgument } from "types";
import { ClientPartsFragment } from "types/graphql";
import styles from "./PartnerTile.module.css";

export type PartnerTileProps = {
  className?: ClassNameArgument;
  data?: ClientPartsFragment | null;
};

const PartnerTile = ({ className, data }: PartnerTileProps) => {
  const { logo, name, websiteUrl } = data?.attributes ?? {};

  const logoSrc = logo?.data?.attributes?.url ?? "";

  const logoElement = (
    <Img
      className={styles["logo"]}
      src={logoSrc}
      alt={name ?? ""}
      width={140}
      height={82}
      title={name}
    />
  );

  return (
    <figure className={classNames(styles["partner-tile"], className)}>
      {websiteUrl ? (
        <a href={websiteUrl} rel="noreferrer noopener" target="_blank">
          {logoElement}
        </a>
      ) : (
        logoElement
      )}
    </figure>
  );
};

export default PartnerTile;
