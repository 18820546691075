import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { ClassNameArgument } from "types";
import styles from "./ReadMore.module.css";

type ReadMoreProps = {
  children: string;
  className?: ClassNameArgument;
  limit: number;
};

const ReadMore: FC<ReadMoreProps> = ({
  children: children,
  className,
  limit: limit,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [showReadMore, setShowReadMore] = useState(false);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const contentElement = ref.current;

    if (
      !contentElement?.textContent ||
      contentElement.textContent.length < limit
    ) {
      return;
    }

    setShowReadMore(true);

    contentElement.innerHTML = isReadMore
      ? contentElement.textContent.slice(0, limit)
      : children;
  }, [children, isReadMore, limit]);

  return (
    <span className={classNames(styles["body"], className)}>
      <span
        ref={ref}
        dangerouslySetInnerHTML={{ __html: children }}
        className={styles["body"]}
      />
      {showReadMore && (
        <span onClick={toggleReadMore} className={styles["read-more"]}>
          {isReadMore ? "...read more" : "show less"}
        </span>
      )}
    </span>
  );
};

export default ReadMore;
