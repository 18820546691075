import { useTranslation } from "react-i18next";
import { PageName } from "types";
import useLinkAlternate from "./useLinkAlternate";
import useLinkCanonical from "./useLinkCanonical";

const useSeo = (pageName: PageName) => {
  const { t } = useTranslation("common");

  const linkAlternate = useLinkAlternate();
  const linkCanonical = useLinkCanonical();

  return {
    title: t(`page.${pageName}.title`),
    description: t(`page.${pageName}.description`),
    linkAlternate,
    linkCanonical,
  };
};

export default useSeo;
