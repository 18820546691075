import { StatisticEntity } from "types/graphql";
import styles from "./Statistics.module.css";

type StatisticsProps = {
  statistics: StatisticEntity[];
};

const Statistics = ({ statistics }: StatisticsProps) => {
  return (
    <section className={styles["statistics"]}>
      {statistics.map((statistic, index) => {
        const number = statistic?.attributes?.amount ?? "";
        const text = statistic?.attributes?.item ?? "";

        return (
          <article
            key={`statistic-${index}`}
            className={styles["statistic-tile"]}
          >
            <span>{number}</span>
            {text}
          </article>
        );
      })}
    </section>
  );
};

export default Statistics;
