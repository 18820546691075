import classNames from "classnames";
import {
  DialogHTMLAttributes,
  ReactNode,
  useEffect,
  useId,
  useRef,
} from "react";
import Icon from "components/Icon";
import useToggleBodyNoScrollAttribute from "hooks/useToggleBodyNoScrollAttribute";
import { ClassNameArgument } from "types";
import styles from "./Modal.module.css";

export type ModalProps = DialogHTMLAttributes<HTMLDialogElement> & {
  className?: ClassNameArgument;
  title?: ReactNode;
};

const Modal = ({ children, className, open, title, ...props }: ModalProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const id = useId();

  const toggleBodyScrollAttribute = useToggleBodyNoScrollAttribute();

  useEffect(() => {
    if (open) {
      dialogRef?.current?.removeAttribute("open");
      dialogRef?.current?.showModal();
    } else {
      dialogRef?.current?.close();
    }
  }, [open]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.attributeName === "open") {
          toggleBodyScrollAttribute(dialogRef.current?.hasAttribute("open"));
        }
      }
    });

    if (dialogRef.current) {
      observer.observe(dialogRef.current, {
        attributes: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [toggleBodyScrollAttribute]);

  return (
    <dialog
      {...props}
      ref={dialogRef}
      id={id}
      className={classNames(styles.modal, className)}
    >
      <header className={styles["header"]}>
        {title && <p className={styles["title"]}>{title}</p>}
        <button
          className={styles["close-button"]}
          aria-controls={id}
          aria-expanded={dialogRef.current?.open ?? false}
        >
          <Icon
            name="close"
            onClick={() => {
              dialogRef.current?.close();
            }}
          />
        </button>
      </header>
      {children}
    </dialog>
  );
};

export default Modal;
