import { isEmpty } from "lodash";
import type { NextPage, GetServerSideProps } from "next";
import Head from "next/head";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useState } from "react";
import client from "common/apolloClient";
import { renderMarkdownProperty } from "common/utils";
import BlogTile from "components/BlogTile";
import Button from "components/Button";
import Carousel from "components/Carousel";
import PartnerTile from "components/PartnerTile";
import ProjectTile from "components/ProjectTile";
import ReferenceTile from "components/ReferenceTile";
import Section from "components/Section";
import ServiceModal from "components/ServiceModal";
import ServiceTile from "components/ServiceTile";
import Statistics from "components/Statistics";
import WorkStepsSection from "components/WorkStepsSection";
import { HOME_PAGE } from "graphql/home-page";
import useSeo from "hooks/useSeo";
import {
  HomePageQuery,
  HomePageQueryVariables,
  ServicePartsFragment,
} from "types/graphql";
import styles from "./index.module.css";

type HomePageProps = {
  homePage: HomePageQuery;
};

const Home: NextPage<HomePageProps> = ({ homePage }) => {
  const [selectedService, setSelectedService] =
    useState<ServicePartsFragment>();

  const { t } = useTranslation("common");

  const { title, description, linkAlternate, linkCanonical } = useSeo("home");

  const services = homePage.services?.data ?? [];
  const statistics = homePage.statistics?.data ?? [];
  const projects = homePage.projects?.data ?? [];
  const references = homePage.references?.data ?? [];
  const articles = homePage.articles?.data ?? [];
  const clients = homePage.clients?.data ?? [];
  const tagline = homePage.homePage?.data?.attributes?.tagline ?? "";
  const subtitle = homePage.homePage?.data?.attributes?.subtitle ?? "";

  const workSteps =
    homePage.homePage?.data?.attributes?.featuredWorkSteps ?? [];

  return (
    <main>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="shortcut icon" href="/assets/favicon.png" />
        <link rel="preload" as="image" href="/assets/introduction-image.webp" />
        {linkAlternate}
        {linkCanonical}
      </Head>
      <Section className={styles["introduction"]} page-padding-top hero>
        <h1 className="sr-only">UNIIT, s.r.o.</h1>
        <section className={styles["introduction-info"]}>
          <h2 className={styles["introduction-info-heading2"]}>{tagline}</h2>
          <h3 className={styles["introduction-info-heading3"]}>
            {t("unique-solutions")}
            <span> {t("universal-skills")}</span>
            <span>UNIIT</span>
          </h3>
          <p>{subtitle}</p>
          <div className={styles["introduction-info-buttons"]}>
            <Link href="#services">
              <Button>{t("service-overview")}</Button>
            </Link>
            <Link href="/projects">
              <Button
                icon="arrow-right"
                iconPosition="after"
                className={styles["introduction-info-buttons-button"]}
              >
                {t("our-portfolio")}
              </Button>
            </Link>
          </div>
        </section>
        <figure className={styles["introduction-image"]}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/assets/introduction-image.webp"
            className={styles["introduction-image"]}
            alt={t("introduction-image") ?? ""}
            width={1366}
            height={768}
          />
        </figure>
        {Array(7)
          .fill(null)
          .map((_, index) => (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              aria-hidden="true"
              key={index}
              alt=""
              className={styles["introduction-background-logo"]}
              data-index={index}
              src="/assets/background-logo.svg"
            />
          ))}
      </Section>
      {!isEmpty(statistics) && <Statistics statistics={statistics} />}
      {!isEmpty(services) && (
        <Section
          id="services"
          className={styles["what-do-we-offer"]}
          title={t("what-do-we-offer") ?? ""}
        >
          <ul className={styles["what-do-we-offer-tiles"]}>
            {services.map((service) => (
              <li
                key={`service-${service.id}`}
                onClick={() => setSelectedService(service)}
              >
                <ServiceTile data={service} />
              </li>
            ))}
          </ul>
          <ServiceModal
            service={selectedService}
            onClose={() => setSelectedService(undefined)}
          />
        </Section>
      )}
      {!isEmpty(workSteps) && <WorkStepsSection workSteps={workSteps} />}
      {!isEmpty(projects) && (
        <Section className={styles["projects"]} title={t("our-projects") ?? ""}>
          <ul className={styles["projects-tiles"]}>
            {projects?.map((project) => (
              <li key={project.id}>
                <ProjectTile data={project} />
              </li>
            ))}
          </ul>
          <Link href="/projects" className={styles["projects-button-wrapper"]}>
            <Button
              variant="inverted"
              className={styles["projects-button-wrapper-button"]}
            >
              {t("view-all-projects")}
            </Button>
          </Link>
        </Section>
      )}
      {!isEmpty(references) && (
        <Section
          className={styles["references"]}
          title={t("references") ?? ""}
          extra-block-padding
        >
          <Carousel
            slidesToShow={2}
            slidesToScroll={1}
            dots
            arrows
            className={styles["references-carousel"]}
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                },
              },
            ]}
          >
            {references.map((reference) => (
              <div key={reference.id}>
                <ReferenceTile
                  data={reference}
                  style={{
                    marginInline: "1rem",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Section>
      )}
      {!isEmpty(clients) && (
        <Section title={t("clients") ?? ""} extra-block-padding>
          <ul className={styles["client-list"]}>
            {clients.map((client) => (
              <li key={client.id}>
                <PartnerTile data={client} />
              </li>
            ))}
          </ul>
        </Section>
      )}
      {!isEmpty(articles) && (
        <Section
          className={styles["blog"]}
          title={t("blog-title") ?? ""}
          extra-block-padding
        >
          <ul className={styles["blog-list"]}>
            {articles.map((article) => (
              <li key={article.id}>
                <BlogTile data={article} />
              </li>
            ))}
          </ul>
        </Section>
      )}
    </main>
  );
};

export const getServerSideProps: GetServerSideProps<HomePageProps> = async ({
  locale = "sk",
}) => {
  const { data: homePage } = await client.query<
    HomePageQuery,
    HomePageQueryVariables
  >({
    query: HOME_PAGE,
    fetchPolicy: "network-only",
    variables: {
      locale,
    },
  });

  return {
    props: {
      homePage: renderMarkdownProperty(
        homePage,
        "references.data.attributes.body",
        "services.data.attributes.content",
        "homePage.data.attributes.featuredWorkSteps.workStep.data.attributes.description"
      ),
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default Home;
