import { useTranslation } from "next-i18next";
import Section from "components/Section";
import WorkStepTile from "components/WorkStepTile/WorkStepTile";
import { HomePageQuery } from "types/graphql";
import styles from "./WorkStepsSection.module.css";

export type WorkStepsSectionProps = {
  workSteps?: NonNullable<
    NonNullable<NonNullable<HomePageQuery["homePage"]>["data"]>["attributes"]
  >["featuredWorkSteps"];
};

const WorkStepsSection = ({ workSteps }: WorkStepsSectionProps) => {
  const { t } = useTranslation("common");

  return (
    <Section
      className={styles["work-steps-section"]}
      title={t("our-processes") ?? ""}
    >
      <ul className={styles["tiles"]}>
        {workSteps?.map((workStep, index) => (
          <li
            className={styles["tiles-item"]}
            key={workStep?.workStep?.data?.id}
          >
            <WorkStepTile
              data={workStep?.workStep?.data}
              iconPosition={index % 2 === 0 ? "end" : "start"}
            />
          </li>
        ))}
      </ul>
    </Section>
  );
};

export default WorkStepsSection;
