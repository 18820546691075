import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { getIllustrationIconName } from "common/utils";
import Button from "components/Button";
import Icon from "components/Icon";
import TagList from "components/TagList";
import { ClassNameArgument } from "types";
import { ServicePartsFragment } from "types/graphql";
import styles from "./ServiceTile.module.css";

export type ServiceTileProps = {
  data?: ServicePartsFragment;
  className?: ClassNameArgument;
};

const ServiceTile = ({ data: service, className }: ServiceTileProps) => {
  const { t } = useTranslation("common");

  const title = service?.attributes?.name ?? "";
  const tags = service?.attributes?.tags?.data ?? [];

  const iconName = getIllustrationIconName(service?.attributes?.icon);

  return (
    <article
      className={classNames(styles["service-tile"], "shadow", className)}
    >
      <div className={styles["service-tile-background"]} aria-hidden="true" />
      <figure className={classNames(styles["service-tile-figure"], className)}>
        <Icon name={iconName} className={styles["icon"]} />
        <figcaption className={styles["caption"]}>
          <p className={styles["title"]} data-line-clamp="2">
            {title}
          </p>
          <TagList limit={4} tags={tags} className={styles["tag-list"]} />
        </figcaption>
        <Button variant="inverted" className={styles["button"]}>
          {t("service-detail")}
        </Button>
      </figure>
    </article>
  );
};

export default ServiceTile;
