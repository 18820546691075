import classNames from "classnames";
import Link from "next/link";
import Img from "components/Img";
import TagList from "components/TagList";
import useDateTimeFormat from "hooks/useDateTimeFormat";
import { ClassNameArgument } from "types";
import { ArticlePartsFragment } from "types/graphql";
import styles from "./BlogTile.module.css";

type BlogTileProps = {
  className?: ClassNameArgument;
  data?: ArticlePartsFragment;
};

const BlogTile = ({ className, data: article }: BlogTileProps) => {
  const { title, date, coverImage, tags } = article?.attributes ?? {};

  const imageSrc = coverImage?.data?.attributes?.url ?? "";

  const dateTimeFormat = useDateTimeFormat({
    dateStyle: "medium",
  });

  return (
    <Link href={`/blog/${article?.id}`} className="inherit-color">
      <article className={classNames(styles["blog-tile"], className, "shadow")}>
        {coverImage && (
          <figure className={styles["blog-tile-image"]}>
            <Img
              height={160}
              width={280}
              src={imageSrc}
              alt={title ?? ""}
              style={{
                objectFit: "cover",
              }}
            />
          </figure>
        )}
        <footer className={styles["blog-tile-footer"]}>
          {date && (
            <time className={styles["blog-tile-date"]} dateTime={date}>
              {dateTimeFormat.format(new Date(date))}
            </time>
          )}
          <p className={styles["blog-tile-title"]} data-line-clamp="2">
            {title}
          </p>
          <TagList
            limit={4}
            tags={tags?.data}
            className={styles["blog-tile-tag-list"]}
          />
        </footer>
      </article>
    </Link>
  );
};

export default BlogTile;
