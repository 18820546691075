import classNames from "classnames";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { HTMLAttributes } from "react";
import Button from "components/Button";
import Img from "components/Img";
import ReadMore from "components/ReadMore/ReadMore";
import useScreenMediaQuery from "hooks/useScreenMediaQuery";
import { ReferencePartsFragment } from "types/graphql";
import styles from "./ReferenceTile.module.css";

type ReferenceTileProps = HTMLAttributes<HTMLElement> & {
  data?: ReferencePartsFragment;
};

const ReferenceTile = ({
  className,
  data: reference,
  ...props
}: ReferenceTileProps) => {
  const { t } = useTranslation("common");
  const [breakpointMatchesLarge] = useScreenMediaQuery("lg");
  const [breakpointMatchesMedium] = useScreenMediaQuery("md");

  const name = reference?.attributes?.fullName ?? "";
  const picture = reference?.attributes?.photo?.data?.attributes?.url ?? "";
  const position = reference?.attributes?.position ?? "";
  const body = reference?.attributes?.body ?? "";
  const project = reference?.attributes?.project?.data?.id ?? "";

  return (
    <article
      className={classNames(styles["reference-tile"], className)}
      {...props}
    >
      <figure className={styles["author-info"]}>
        {picture && (
          <Img
            className={styles["author-image"]}
            src={picture}
            alt="Profile picture"
            width={64}
            height={64}
            style={{
              objectFit: "cover",
            }}
          />
        )}
        <address>
          <p className={styles["author-name"]}>{name}</p>
          <p className={styles["author-position"]}>{position}</p>
        </address>
      </figure>
      <ReadMore
        className={styles["body"]}
        limit={
          breakpointMatchesLarge ? 275 : breakpointMatchesMedium ? 125 : 275
        }
        children={body}
      />
      <Link
        href={`/projects/${project}`}
        className={styles["author-info-button"]}
      >
        <Button variant="inverted">{t("project-details")}</Button>
      </Link>
    </article>
  );
};

export default ReferenceTile;
