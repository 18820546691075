import { kebabCase } from "lodash";
import { useEffect, useRef, useState } from "react";
import Icon, { IconName } from "components/Icon";
import { WorkStepPartsFragment } from "types/graphql";
import styles from "./WorkStepTile.module.css";

export type WorkStepTileProps = {
  data?: WorkStepPartsFragment | null;
  iconPosition?: "start" | "end";
};

const WorkStepTile = ({
  data: workStep,
  iconPosition = "start",
}: WorkStepTileProps) => {
  const iconName = `box-${kebabCase(
    workStep?.attributes?.icon ?? "programming"
  )}` as IconName;

  const { summary, description } = workStep?.attributes ?? {};
  const ref = useRef<HTMLDetailsElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const refElement = ref.current;

    const handleToggle = (event: Event) => {
      setOpen((event.currentTarget as HTMLDetailsElement).open);
    };

    refElement?.addEventListener("toggle", handleToggle);

    return () => {
      refElement?.removeEventListener("toggle", handleToggle);
    };
  }, []);

  return (
    <article
      className={styles["work-step-tile"]}
      data-icon-position={iconPosition}
      data-open={open}
    >
      <details className={styles["content"]} ref={ref}>
        <summary className={styles["content-summary"]}>{summary}</summary>
        <div
          className={styles["content-description"]}
          dangerouslySetInnerHTML={{ __html: description ?? "" }}
        />
      </details>
      <button
        aria-label={summary}
        className={styles["icon"]}
        onClick={() => {
          if (ref.current) {
            ref.current.open = !ref.current.open;
          }
        }}
      >
        <Icon name={iconName} />
      </button>
    </article>
  );
};

export default WorkStepTile;
